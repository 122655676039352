import useMyProfile from "@/services/profile/useMyProfile";
import useCompanyProfiles from "@/services/profile/useCompanyProfiles";
import { ACCOUNT_ROLE } from "@/utils/app/constants";
import { useMutation } from "@tanstack/react-query";
import useMyCompanyProfile from "@/services/companyprofile/useMyCompanyProfile";
import useAllRawAccessPermissions from "@/services/access_permission/useAllRawAccessPermissions";
import { useMemo } from "react";
import { difference } from "lodash";
import { useGlobalConfig } from "@/providers/app/GlobalConfigProvider";

const requiresAdmin: string[] = [];

export function useRefetchPermissions() {
  const urlParams = new URLSearchParams(window.location.search);
  const path = window.location.pathname;
  const parts = path.split("/");
  const target = parts[4];
  const validKeys = ["contact", "crmdeal", "crmorder", "crmproduct", "enterprise", "listobject", "list", "order", "products", "deal"];

  const isTargetValid = validKeys.includes(target);
  
  const params = isTargetValid
    ? {
        object: target === "list" ? "listobject" 
              : target === "order" ? "crmorder" 
              : target === "products" ? "crmproduct" 
              : target === "products" ? "crmproduct" 
              : target === "deal" ? "crmdeal" 
              : target,
        fields: "id,code"
      }
    : undefined;
  const { refetch: refetchProfileData } = useMyProfile();
  const { refetch: refetchCompanyProfile } = useMyCompanyProfile();
  const { refetch: refetchPermissions } = useAllRawAccessPermissions({params});

  return useMutation({
    mutationFn: async () => {
      await refetchProfileData();
      await refetchCompanyProfile();
      await refetchPermissions();
    },
  });
}

function useHasPermission(permission_name: string[]) {
  const urlParams = new URLSearchParams(window.location.search);
  const path = window.location.pathname;
  const parts = path.split("/");
  const target = parts[4];
const validKeys = ["contact", "crmdeal", "crmorder", "crmproduct", "enterprise", "listobject", "list", "order", "products", "deal"];

const isTargetValid = validKeys.includes(target);

const params = isTargetValid
  ? {
      object: target === "list" ? "listobject" 
            : target === "order" ? "crmorder" 
            : target === "products" ? "crmproduct" 
            : target === "products" ? "crmproduct" 
            : target === "deal" ? "crmdeal" 
            : target,
      fields: "id,code"
    }
  : undefined;

  const { checkingPermission } = useGlobalConfig();
  const { data: myProfileData } = useMyProfile();
  const { data: allPermissionsData } = useAllRawAccessPermissions({params});
  const { data: myCompanyProfileData } = useMyCompanyProfile();
  const myProfile = myProfileData?.data;
  const myCompanyProfile = myCompanyProfileData?.data;
  const { data: companyProfileData } = useCompanyProfiles({
    pagination: {
      page: 1,
      page_size: 1,
    },
    currentFilters: {
      search: myProfile?.email,
    },
  });
  const companyProfile = companyProfileData?.data?.results || [];

  const accountRole = companyProfile[0]?.role;
  const isAdmin = accountRole === ACCOUNT_ROLE.ADMIN;

  const allDisabledPermission = useMemo(() => {
    const allPermissionsUserHad =
      myCompanyProfile?.permissions?.map((e) => e.code) || [];

    const allPermissionsAvailable =
      allPermissionsData?.data?.map((e) => e.code) || [];

    return difference(allPermissionsAvailable, allPermissionsUserHad);
  }, [allPermissionsData?.data, myCompanyProfile?.permissions]);

  return permission_name.map((permission) => {
    if (!checkingPermission) {
      return true;
    }
    if (requiresAdmin.includes(permission)) {
      return isAdmin;
    }
    if (allDisabledPermission?.includes(permission)) {
      return false;
    }

    return true;
  });
}

export default useHasPermission;
